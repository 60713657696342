export const AllTerms = [
  {
    name: "FIRST TERM",
    code: "FIRST TERM",
    id: "001",
  },
  {
    name: "SECOND TERM",
    code: "SECOND TERM",
    id: "002",
  },
  {
    name: "THIRD TERM",
    code: "THIRD TERM",
    id: "003",
  },
];
