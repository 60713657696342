export const AllSessions = [
    {
        name:"2023/2024",
        code: "2023",
        id: "001"
    },
    {
        name:"2024/2025",
        code: "2024",
        id: "002"
    },
    {
        name:"2025/2026",
        code: "2025",
        id: "003"
    },
    {
        name:"2026/2027",
        code: "2026",
        id: "004"
    },
  ]