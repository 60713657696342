import React from 'react'
import styled from 'styled-components'
export default function Notify() {
  return (
    <Container className='p-5'>
<div>
    <h4>Send a Notification</h4>
    <p>send a specific message to your staff.</p>
</div>
    </Container>
  )
}
const Container = styled.div`

`
