const { v4: uuidv4 } = require('uuid');
export const AllSubjects =[
    {
        name: "Number Work",
        code: "FES_001",
        id: "001",
      },
      {
        name: "Letter Work",
        code: "FES_002",
        id: "002",
      },
      {
        name: "Handwritting",
        code: "FES_003",
        id: "003",

      },
      {
        name: "Communication Ability",
        code: "FES_004",
        id: "004",

      },
      {
        name: "Rhymes and Poems",
        code: "FES_005",
        id: "005",
      },
      {
        name: "Islamic Ethics/Arabic",
        code: "FES_006",
        id: "006",
      },
      {
        name: "Social Norms: Manners / Morals",
        code: "FES_007",
        id: "007",
      },
      {
        name: "Creative Activities / Ideas / Arts",
        code: "FES_008",
        id: "008",
      },
      {
        name: "Physical Work",
        code: "FES_009",
        id: "009",
      },
      {
        name: "DEEN",
        code: "FES_010",
        id: "010",
      },
      {
        name: "COMPUTER",
        code: "FES_011",
        id: "011",
      },
      {
        name: "Practical Life",
        code: "FES_012",
        id: "012",
      },
      {
        name: "Health Habit",
        code: "FES_013",
        id: "013",
      },
      {
        name: "Nature Study",
        code: "FES_014",
        id: "014",
      },
      {
        name: "English Language",
        code: "FBS_001",
        id: "001",
      },
      {
        name: "Mathematics",
        code: "FBS_002",
        id: "002",
      },
      {
        name: "Quantitative Reasoning",
        code: "FBS_003",
        id: "003",
      },
      {
        name: "Verbal Reasoning",
        code: "FBS_004",
        id: "004",
      },
      {
        name: "Handwritting",
        code: "FBS_005",
        id: "005",
      },
      {
        name: "Quran and Hadith",
        code: "FBS_006",
        id: "006",
      },
      {
        name: "Fine Art",
        code: "FBS_007",
        id: "007",
      },
      {
        name: "Civic Education ",
        code: "FBS_008",
        id: "008",
      },
      {
        name: "P.H.E",
        code: "FBS_009",
        id: "009",
      },
      {
        name: "Home Economics",
        code: "FBS_010",
        id: "010",
      },
      {
        name: "I.R.S",
        code: "FBS_011",
        id: "011",
      },
      {
        name: "Moral Instruction",
        code: "FBS_012",
        id: "012",
      },
      {
        name: "Social Studies",
        code: "FBS_013",
        id: "013",
      },
      {
        name: "Basic Science",
        code: "FBS_014",
        id: "014",
      },
      {
        name: "Agric. Science",
        code: "FBS_015",
        id: "015",
      },
      {
        name: "Computer Education",
        code: "FBS_016",
        id: "016",
      },
      {
        name: "French",
        code: "FBS_017",
        id: "017",
      },
      {
        name: "Yoruba",
        code: "FBS_018",
        id: "018",
      },
      {
        name: "Vocational",
        code: "FBS_019",
        id: "019",
      },
      {
        name: "Diction",
        code: "FBS_020",
        id: "020",
      },
      {
        name: "History",
        code: "FBS_021",
        id: "021",
      },
      {
        name: "Arabic",
        code: "FBS_022",
        id: "022",
      },
      {
        name: "English Language",
        code: "FJS_001",
        id: "001",
      },
      {
        name: "Mathematics",
        code: "FJS_002",
        id: "002",
      },
      {
        name: "Yoruba Language",
        code: "FJS_003",
        id: "003",
      },
      {
        name: "Basic Science",
        code: "FJS_004",
        id: "004",
      },
      {
        name: "Basic Tech.",
        code: "FJS_005",
        id: "005",
      },
      {
        name: "P.H.E",
        code: "FJS_006",
        id: "006",
      },
      {
        name: "Computer Studies",
        code: "FJS_007",
        id: "007",
      },
      {
        name: "Home Economics",
        code: "FJS_008",
        id: "008",
      },
      {
        name: "Agric. Science",
        code: "FJS_009",
        id: "009",
      },
      {
        name: "I.R.S",
        code: "FJS_010",
        id: "010",
      },
      {
        name: "Social Studies",
        code: "FJS_011",
        id: "011",
      },
      {
        name: "Civic Education",
        code: "FJS_012",
        id: "012",
      },
      {
        name: "Creative and Cultural Arts",
        code: "FJS_013",
        id: "013",
      },
      {
        name: "Business Studies",
        code: "FJS_014",
        id: "014",
      },
      {
        name: "French",
        code: "FJS_015",
        id: "015",
      },
      {
        name: "Arabic",
        code: "FJS_016",
        id: "016",
      },
      {
        name: "Quran and Hadith",
        code: "FJS_017",
        id: "017",
      },
      {
        name: "Diction",
        code: "FJS_018",
        id: "018",
      },
      {
        name: "History",
        code: "FJS_019",
        id: "019",
      },
      {
        name: "English Language",
        code: "FSS_001",
        id: "001",
      },
      {
        name: "Mathematics",
        code: "FSS_002",
        id: "002",
      },
      {
        name: "Biology",
        code: "FSS_003",
        id: "003",
      },
      {
        name: "Physics",
        code: "FSS_004",
        id: "004",
      },
      {
        name: "Chemistry",
        code: "FSS_005",
        id: "005",
      },
      {
        name: "Further Mathematics",
        code: "FSS_006",
        id: "006",
      },
      {
        name: "Yoruba Language",
        code: "FSS_007",
        id: "007",
      },
      {
        name: "Civic Education",
        code: "FSS_008",
        id: "008",
      },
      {
        name: "Computer Studies",
        code: "FSS_009",
        id: "009",
      },
      {
        name: "Data Processing",
        code: "FSS_010",
        id: "010",
      },
      {
        name: "I.R.S",
        code: "FSS_011",
        id: "011",
      },
      {
        name: "Arabic",
        code: "FSS_012",
        id: "012",
      },
      {
        name: "Economics",
        code: "FSS_013",
        id: "013",
      },
      {
        name: "Geography",
        code: "FSS_014",
        id: "014",
      },
      {
        name: "Agric. Science",
        code: "FSS_015",
        id: "015",
      },
      {
        name: "Government",
        code: "FSS_016",
        id: "016",
      },
      {
        name: "Literature in English",
        code: "FSS_017",
        id: "017",
      },
      {
        name: "Financial Accounting",
        code: "FSS_018",
        id: "018",
      },
      {
        name: "Commerce",
        code: "FSS_019",
        id: "019",
      },
]