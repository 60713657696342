import { useParams } from "react-router-dom";
import {styled} from "styled-components"
export default function Scheme (){
    const { identity } = useParams();
    return(
        <Container>

        </Container>
    )
}
const Container = styled.div`

`