import React from "react";
import styled from "styled-components";
export default function Calendar() {
  return (
    <Wrapper className="container py-5">
            <p>currently on build, check back later.</p>
    </Wrapper>
  );
}
const Wrapper = styled.div`
p{
  color: red;
}
`;
