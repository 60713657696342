import React from "react";

export default function Import() {
  return (
    <>
      <>
        <h1>Import</h1>
      </>
    </>
  );
}
